const formData = [
  // index = 0 - config formik
  [
    {
      type: 'text',
      name: {
        label: 'Name',
        input: 'name',
      },
    },
    {
      type: 'text',
      name: {
        label: 'Title',
        input: 'title',
      },
    },
    {
      type: 'text',
      name: {
        label: 'Slug',
        input: 'slug',
      },
    },
    {
      type: 'text',
      name: {
        label: 'Description',
        input: 'description',
      },
      inputType: 'multiline',
    },
  ],
];

export { formData };
