import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    '&:focus': {
      outline: 'none',
    },
  },
}));

function ButtonSample({
  name,
  data = null,
  color = 'primary',
  method,
  icon,
  style = {},
}) {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      color={color}
      className={classes.button}
      startIcon={icon}
      style={style ? style : {}}
      onClick={() => method(data)}>
      {name}
    </Button>
  );
}

export default ButtonSample;
