import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { actionAddUserAsJson } from '../../Users/actions';
import { Box, Grid, Button } from '@material-ui/core';
import JsonEditor from '../../Other/JsonEditor';
import { Spinner } from '../../../shared';

const useStyles = makeStyles((theme) => ({
  footerBodyBlock: {
    display: 'flex',
  },
  blockLeft: {
    position: 'relative',
    // padding: '0px!important',
    '& > div': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  blockRight: {
    // padding: '0px!important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& h6': {
      marginBottom: '.5rem',
    },
  },
}));

function AddUserModal({
  config: { project_id },
  loadingSaveUserAsJson,
  actionAddUserAsJson,
}) {
  const currentJsonRef = useRef({
    email: '<email>',
    password: '<password>',
    full_name: '<Full Name>',
  });
  const classes = useStyles();
  const [json, setJson] = useState(currentJsonRef.current);
  const loading = loadingSaveUserAsJson;

  const handleChangeJSON = (newJson) => {
    setJson(newJson);
  };

  const bodyRender = () => {
    return (
      <Box position="relative">
        <Button
          disabled={
            json === null ||
            (Array.isArray(json) &&
              (!json.length ||
                json.filter((d) => !Object.keys(d).length).length)) ||
            (typeof json === 'object' && !Object.keys(json).length) ||
            loading
              ? true
              : false
          }
          variant="contained"
          color="primary"
          style={{
            position: 'absolute',
            zIndex: 100,
            right: 10,
            bottom: 35,
          }}
          onClick={() => actionAddUserAsJson({ id: project_id, data: json })}>
          Save
          {loading ? (
            <Spinner
              color="#fff"
              style={{
                position: 'absolute',
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              }}
            />
          ) : null}
        </Button>
        <JsonEditor
          data={currentJsonRef.current}
          searchData={json}
          handleJSON={handleChangeJSON}
          style={{ width: '100%' }}
        />
      </Box>
    );
  };

  return (
    <Grid container spacing={1} className={classes.footerBodyBlock}>
      <Grid item xs={12} className={classes.blockRight}>
        {bodyRender()}
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (rootState, props) => {
  const state = rootState.modal;
  const stateUsers = rootState.users;
  return {
    ...state,
    config: state.config.modalConfig,
    loadingSaveUserAsJson: stateUsers.loadingSaveUserAsJson === 'saving',
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionAddUserAsJson: (data) => dispatch(actionAddUserAsJson(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
