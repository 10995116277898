import React from 'react';
import { pick } from 'lodash';
import classnames from 'classnames';
import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import { TableSpinner } from './TableSpinner';
// import { Typography } from '../typography';
import Typography from '@material-ui/core/Typography';

function descendingComparator(a1, b1, orderBy) {
  let a = a1[orderBy];
  let b = b1[orderBy];

  if (orderBy === 'created_at') {
    a = new Date(a1[orderBy]).getTime();
    b = new Date(b1[orderBy]).getTime();
  }
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const MemoTableCell = React.memo(
  (props) => {
    return (
      <TableCell classes={{ root: props.tableClasses.cell.root }}>
        {props.children(props)}
      </TableCell>
    );
  },
  (prevP, nextP) => {
    if (prevP.row === nextP.row) {
      if (prevP.checked !== nextP.checked) {
        return false;
      }
      return true;
    }
    return false;
  }
);

// const filterData = (data, searchString, filterKeys) => {
//   if (searchString === '') {
//     return data;
//   }
//   return data.filter((item) => {
//     return Object.values(pick(item, filterKeys))
//       .filter((val) => typeof val === 'string')
//       .some((val) => val.toLowerCase().includes(searchString.toLowerCase()));
//   });
// };

export const Table = (props) => {
  const {
    headerRow,
    bodyRow,
    rows,
    loading = false,
    filterString = '',
    selectedRows = {},
    rowIdKey = 'id',
    defaultSort = { name: 'name', order: 'asc' },
    cellClasses = {},
    emptyMessage = '',
  } = props;
  const [order, setOrder] = React.useState(defaultSort.order);
  const [orderBy, setOrderBy] = React.useState(defaultSort.name);

  const handleSort = (column) => {
    const isAsc = orderBy === column && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
  };

  const filterKeys = headerRow.map((header) => header.id).filter(Boolean);
  const tableClasses = {
    cell: { root: cellClasses.root ? cellClasses.root : '' },
  };

  return (
    <div style={{ position: 'relative', minHeight: '150px' }}>
      <TableContainer style={{ maxHeight: '23.5rem' }}>
        <MUITable aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              {headerRow.map((header) =>
                header.sortable ? (
                  <TableCell
                    key={header.id}
                    sortDirection={orderBy === header.id ? order : false}
                    classes={{
                      root: classnames(
                        tableClasses.cell.root,
                        header.align ? header.align : '',
                        header.width ? header.width : ''
                      ),
                      head: header.class,
                    }}>
                    <TableSortLabel
                      active={orderBy === header.id}
                      direction={orderBy === header.id ? order : 'asc'}
                      onClick={() => handleSort(header.id)}>
                      {header.label}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell
                    key={header.id}
                    classes={{
                      root: classnames(
                        tableClasses.cell.root,
                        header.align ? header.align : '',
                        header.width ? header.width : ''
                      ),
                      head: header.class,
                    }}>
                    {header.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(
              rows,
              // filterData(rows, filterString, filterKeys),
              getComparator(order, orderBy)
            ).map((row) => {
              return (
                <TableRow key={row[rowIdKey]}>
                  {bodyRow.map((key, i) => {
                    if (typeof key === 'string') {
                      return (
                        <MemoTableCell
                          key={key + i}
                          row={row}
                          accessor={key}
                          tableClasses={tableClasses}>
                          {() => row[key]}
                        </MemoTableCell>
                      );
                    } else {
                      const CustomElem = key;
                      return (
                        <MemoTableCell
                          key={key + i}
                          row={row}
                          checked={Boolean(selectedRows[row[rowIdKey]])}
                          tableClasses={tableClasses}>
                          {({ row, checked }) => (
                            <CustomElem row={row} checked={checked} />
                          )}
                        </MemoTableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MUITable>
        <TableSpinner loading={loading} />
      </TableContainer>
      {/* {emptyMessage && !rows.length && (
        <div
          style={{
            padding: '16px',
            border: '1px rgb(224, 224, 224) solid',
            borderTop: 'none',
          }}>
          <Typography size="body">{emptyMessage}</Typography>
        </div>
      )} */}
    </div>
  );
};
