import React, { useEffect } from 'react';
import {
  Route,
  Redirect,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import {
  actionInitProject,
  actionDidInitProject,
  actionResetWorkspaceState,
} from '../../store/actions';

import Header from '../Other/Header';
import Footer from '../Other/Footer';
import Sidebar from '../Other/Sidebar';
import Config from '../projectPageContent/Config';
import Users from '../projectPageContent/Users';
import Datasources from '../projectPageContent/Datasources';

import _v from '../../styles/_variables.scss';

function Project({
  projects,
  project,
  actionInitProject,
  actionDidInitProject,
  actionResetWorkspaceState,
}) {
  const { id } = useParams();
  let { path } = useRouteMatch();

  useEffect(() => {
    // if projects data true get from projects else make get project request
    if (projects.length) {
      const idx = projects.findIndex((d) => d.id === +id);
      if (idx !== -1) {
        actionDidInitProject(projects[idx]);
        actionResetWorkspaceState();
      }
    } else {
      actionInitProject(id);
    }
  }, [id]);

  if (!Object.keys(project).length) return null;

  return (
    <>
      <Header name={project.title} />
      <div
        className="row m-0 project-page flex-grow-1"
        style={{ overflow: 'hidden' }}>
        <div
          className="col-md-12 p-0 d-flex"
          style={{ width: '100%', marginTop: '4rem', height: '100%' }}>
          <Sidebar />
          <div
            className="row mt-0 mr-0 mb-0 tab-content"
            style={{
              marginLeft: _v.sidebarWidth,
              width: '100%',
              height: 'calc(100% - 4rem)',
              overflow: 'auto',
            }}>
            <div
              className="col-md-12 d-flex flex-column"
              style={{
                padding: '1.5rem',
                paddingBottom: '0',
              }}>
              <Switch>
                <Redirect
                  exact
                  from="/projects/:id"
                  to="/projects/:id/config"
                />
                <Route exec path={`${path}/config`}>
                  <Config />
                </Route>
                <Route path={`${path}/datasources`}>
                  <Datasources />
                </Route>
                <Route path={`${path}/users`}>
                  <Users />
                </Route>
              </Switch>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, props) => {
  const { projects, project } = state.data;
  return {
    ...state,
    projects: projects || [],
    project: project || {},
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionDidInitProject: (data) => dispatch(actionDidInitProject(data)),
    actionInitProject: (id) => dispatch(actionInitProject(id)),
    actionResetWorkspaceState: () => dispatch(actionResetWorkspaceState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);
