export const ModalTypes = {
  AddDatasourceModal: 'AddDatasourceModal',
  AddUserModal: 'AddUserModal',
};

export const ModalOptions = {
  [ModalTypes.AddDatasourceModal]: {
    headerText: 'Add new datasource',
  },
  [ModalTypes.AddUserModal]: {
    headerText: 'Add New User',
  },
};
