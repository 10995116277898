import React, { useMemo, useState, useEffect } from 'react';
import {
  Box,
  Tooltip,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '../../../shared';
import classnames from 'classnames';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import PaperSample from '../../Other/PaperSample';
import SimpleContainer from './SimpleContainer';
import SimpleMaterialTable from '../../Other/SimpleMaterialTable';

import { colors } from './../../../shared/theme';

const statuses = [
  {
    title: 'Ready',
    status: 'ready',
    color: colors.success.main,
  },
  {
    title: 'Loading',
    status: 'loading',
    color: colors.warning.main,
  },
  {
    title: 'Processing',
    status: 'processing',
    color: colors.warning.main,
  },
  {
    title: 'Not available',
    status: 'not_available',
    color: colors.text.secondary,
  },
  {
    title: 'Outdated',
    status: 'outdated',
    color: colors.info.secondary,
  },
  {
    title: 'Failed',
    status: 'failed',
    color: colors.error.main,
  },
  {
    title: 'Deleting',
    status: 'deleting',
    color: colors.text.primary,
  },
  {
    title: 'Disabled',
    status: 'disabled',
    color: colors.text.disabled,
  },
];

const formatDate = (date) => {
  const arr = new Date(date)
    .toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timezone: 'UTC',
    })
    .split('/');

  const el = arr[0].length === 1 ? 0 + arr[0] : arr[0];
  arr[0] = arr[1].length === 1 ? 0 + arr[1] : arr[1];
  arr[1] = el;

  return arr.join('/');
};

const useStyles = makeStyles((theme) => {
  return {
    table: {
      height: '21rem',
      padding: '0px',
    },
    controlsBox: {
      display: 'flex',
    },
    tooltip: {
      display: 'flex',
      alignItems: 'center',
    },
    statusIcon: {
      width: 9,
      height: 9,
      borderRadius: '100%',
      marginRight: 8,
    },
    verDivider: {
      color: '#000000de',
      marginRight: '0.4rem',
    },
    shimmer: {
      animation: '$myanimation 1.5s infinite ease-in',
    },
    '@keyframes myanimation': {
      '0%': { opacity: 1 },
      '50%': { opacity: 0 },
      '100%': { opacity: 1 },
    },
    mode: {
      width: 25,
      height: 25,
      padding: '2px 8px',
      borderRadius: 100,
      margin: 2,
    },
    paperActionsMenu: {
      width: 150,
    },
  };
});

const DatasourcesTable = ({
  data,
  loading,
  selectedDatasourceRow,
  handleDeleteDatasource,
  handleSelectDatasourceRow,
  handleUpdateDatasource,
}) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const handleIsDeleting = (row, is) => {
    setRows(
      rows.map((el) =>
        el.datasource_id === row.datasource_id
          ? {
              ...el,
              deleting: is,
            }
          : el
      )
    );
  };

  useEffect(() => {
    setRows(data);
  }, [data]);

  const columns = useMemo(
    () => [
      {
        title: 'id',
        field: 'datasource_id',
        props: {
          style: {
            width: 30,
          },
        },
      },
      {
        title: 'Name',
        field: 'name',
        props: {
          style: {
            width: '40%',
          },
        },
      },
      {
        title: 'Date',
        field: 'date',
        props: {
          style: {
            width: '15%',
          },
        },
        component: (row) => (
          <Typography variant="body2">{formatDate(row.date)}</Typography>
        ),
      },
      {
        title: '# Rows',
        field: 'num_of_rows',
        props: {
          style: {
            width: '10%',
          },
        },
        component: (row) => (
          <Typography variant="body2">{row.num_of_rows || 0}</Typography>
        ),
      },
      {
        title: 'Mode',
        field: 'modes',
        props: {
          style: {
            width: '10%',
          },
        },
        component: (row) => (
          <Box display="flex">
            {row.modes.map((el, i) => (
              <Tooltip
                key={i}
                title={el}
                placement="top"
                className={classes.tooltip}>
                <Typography
                  variant="body2"
                  className={classes.mode}
                  style={{
                    backgroundColor:
                      el === 'production'
                        ? colors.info.light
                        : colors.warning.light,
                  }}>
                  {el[0].toUpperCase()}
                </Typography>
              </Tooltip>
            ))}
          </Box>
        ),
      },
      {
        title: 'Cache',
        field: 'query_cache',
        props: {
          style: {
            width: '10%',
          },
        },
        component: (row) => (
          <Box display="flex" alignItems="center">
            <div
              style={{
                backgroundColor: row.query_cache
                  ? colors.success.main
                  : colors.text.disabled,
              }}
              className={classes.statusIcon}></div>
            <Typography variant="body2">
              {row.query_cache ? 'Yes' : 'No'}
            </Typography>
          </Box>
        ),
      },
      {
        title: 'Status',
        field: 'status',
        props: {
          style: {
            width: '15%',
          },
        },
        component: (row) => {
          const status = statuses.find((s) => s.status === row.status) || {};

          return (
            <Box display="flex" alignItems="center">
              <div className={classes.statusIcon}>
                <Box
                  style={{ backgroundColor: status.color }}
                  className={classnames(classes.statusIcon, {
                    [classes.shimmer]: [
                      'loading',
                      'processing',
                      'deleting',
                    ].includes(status.status),
                  })}></Box>
              </div>
              <Typography variant="body2">{status.title}</Typography>
            </Box>
          );
        },
      },
      {
        title: '',
        props: {
          style: {
            width: 30,
          },
        },
        component: (row) => (
          <Box display="flex">
            <Divider
              orientation="vertical"
              flexItem
              className={classes.verDivider}
            />
            <IconButton
              id={row.datasource_id}
              className={'no-select'}
              size="small"
              className={classes.actionsButton}
              onClick={handleActionClick}>
              <MoreHorizIcon className={'no-select'} fontSize="small" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              classes={{
                paper: classes.paperActionsMenu,
              }}
              open={anchorEl ? +anchorEl.id === row.datasource_id : false}
              onClose={() => {
                handleIsDeleting(row, false);
                handleActionClose();
              }}>
              <MenuItem
                onClick={() => {
                  handleActionClose();
                  handleUpdateDatasource(
                    { action: 'restart' },
                    row.datasource_id
                  );
                  handleIsDeleting(row, false);
                }}>
                Restart
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleActionClose();
                  handleUpdateDatasource(
                    {
                      action: row.status === 'disabled' ? 'enable' : 'disable',
                    },
                    row.datasource_id
                  );
                  handleIsDeleting(row, false);
                }}>
                {row.status === 'disabled' ? 'Enable' : 'Disable'}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleActionClose();
                  handleUpdateDatasource(
                    { query_cache: row.query_cache ? false : true },
                    row.datasource_id
                  );
                  handleIsDeleting(row, false);
                }}>
                {row.query_cache ? 'Cache Off' : 'Cache On'}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleActionClose();
                  handleUpdateDatasource(
                    { action: 'clear_cache' },
                    row.datasource_id
                  );
                  handleIsDeleting(row, false);
                }}>
                Clear Cache
              </MenuItem>
              {row.deleting ? (
                <MenuItem>
                  <Box display="flex" alignItems="center">
                    <Box mr={2}>
                      <Typography variant="body1">Delete?</Typography>
                    </Box>
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleDeleteDatasource(row.datasource_id);
                        handleActionClose();
                      }}>
                      <CheckIcon fontSize="small" />
                      {/*Delete button*/}
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleIsDeleting(row, false)}>
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </MenuItem>
              ) : (
                <MenuItem onClick={() => handleIsDeleting(row, true)}>
                  Delete
                </MenuItem>
              )}
            </Menu>
          </Box>
        ),
      },
    ],
    [anchorEl, rows]
  );

  return (
    <SimpleContainer>
      <PaperSample style={{ position: 'relative', padding: '.5rem 0rem' }}>
        <SimpleMaterialTable
          columns={columns}
          data={rows.map((el, i) => ({
            deleting: el.deleting,
            name: el.config.name,
            status: el.status,
            datasource_id: el.datasource_id,
            date: el.updated_at || el.created_at,
            modes: el.modes,
            num_of_rows: el.num_of_rows,
            query_cache: el.query_cache,
          }))}
          loading={loading}
          className={classes.table}
          searchFieldProps={{
            style: {
              marginLeft: '1rem',
            },
          }}
          tableContainerProps={{
            style: {
              height: '17.5rem',
            },
          }}
          rowsProps={(row, ind) => ({
            onClick: (e) =>
              (selectedDatasourceRow &&
                selectedDatasourceRow.datasource_id === row.datasource_id) ||
              e.target.classList.contains('no-select') ||
              anchorEl
                ? null
                : handleSelectDatasourceRow(
                    rows.find((d) => d.datasource_id === row.datasource_id)
                  ),
            style: {
              backgroundColor:
                selectedDatasourceRow &&
                row.datasource_id === selectedDatasourceRow.datasource_id
                  ? '#e0e0e0'
                  : '',
              cursor: 'pointer',
            },
          })}
        />
      </PaperSample>
    </SimpleContainer>
  );
};

export default DatasourcesTable;
