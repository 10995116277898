import React, { useState, useRef, forwardRef, useEffect } from 'react';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import { tableIcons } from './datasourcesComponents/TableIcons';
import { connect } from 'react-redux';
import {
  actionInitUsers,
  actionAddUser,
  actionDeleteUser,
  actionUpdateUser,
  actionCreateUser,
} from '../Users/actions';
import { actionShowModal } from '../Modals/actions';
import { ModalTypes } from '../Modals/constants';

import Title from '../Other/Title';
import AddBox from '@material-ui/icons/AddBox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import './General.scss';

const _columns = [
  { title: 'Full Name', field: 'full_name', disableClick: true },
  { title: 'Email', field: 'email', disableClick: true },
];

const useStyles = makeStyles((theme) => ({
  modalClassName: {
    minWidth: '27rem',
  },
}));

const Users = ({
  project,
  users,
  loadingUsers,
  actionInitUsers,
  actionDeleteUser,
  actionUpdateUser,
  actionCreateUser,
  actionShowModal,
}) => {
  const classes = useStyles();
  const materialTableRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const loading = loadingUsers;

  useEffect(() => {
    actionInitUsers({ id: project.id });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleJSON = () => {
    handleCloseMenu();
    actionShowModal(ModalTypes.AddUserModal, {
      modalClassName: classes.modalClassName,
      modalConfig: {
        project_id: project.id,
      },
    });
  };

  const handleNotJSON = () => {
    setAnchorEl(null);
    const materialTable = materialTableRef.current;

    materialTable.dataManager.changeRowEditing();
    materialTable.setState({
      ...materialTable.dataManager.getRenderState(),
      showAddRow: true,
    });
  };

  return (
    <>
      <Title title="Users List" rowStyle={{ margin: 0 }} />
      <div className="row mt-4 flex-grow-1 users-table">
        <div className="col-md-12">
          <MaterialTable
            title=""
            columns={_columns}
            data={users}
            isLoading={loading}
            icons={tableIcons}
            tableRef={materialTableRef}
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            options={{
              actionsColumnIndex: -1,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            actions={[
              {
                icon: forwardRef((props, ref) => (
                  <AddBox {...props} ref={ref} />
                )),
                tooltip: 'Add User(s)',
                isFreeAction: true,
                onClick: (event) => handleClick(event),
              },
            ]}
            onRowClick={(e, rowData) => {}}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  actionCreateUser({
                    id: project.id,
                    data: newData,
                    resolve: () => resolve(),
                  });
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  actionUpdateUser({
                    project_id: oldData.project_id,
                    user_id: oldData.user_id,
                    data: newData,
                    resolve: () => resolve(),
                  });
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  actionDeleteUser({
                    project_id: oldData.project_id,
                    user_id: oldData.user_id,
                    resolve: () => resolve(),
                  });
                }),
            }}
          />
        </div>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}>
        <MenuItem onClick={handleJSON}>add as JSON</MenuItem>
        <MenuItem disabled onClick={handleNotJSON}>
          add as not JSON
        </MenuItem>
      </Menu>
    </>
  );
};

const mapStateToProps = (state, props) => {
  const { project } = state.data;
  const { users, loadingUsers } = state.users;
  return {
    ...state,
    project: project || {},
    users: users || [],
    loadingUsers: loadingUsers === 'saving',
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionInitUsers: (data) => dispatch(actionInitUsers(data)),
    actionAddUser: (data) => dispatch(actionAddUser(data)),
    actionDeleteUser: (data) => dispatch(actionDeleteUser(data)),
    actionUpdateUser: (data) => dispatch(actionUpdateUser(data)),
    actionCreateUser: (data) => dispatch(actionCreateUser(data)),
    actionShowModal: (modalType, config) =>
      dispatch(actionShowModal(modalType, config)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
