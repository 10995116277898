export const SHOW_MODAL = 'SHOW_MODAL';
export const actionShowModal = (modalType, config = {}) => ({
  type: SHOW_MODAL,
  payload: { modalType, config },
});

export const HIDE_MODAL = 'HIDE_MODAL';
export const actionHideModal = () => ({
  type: HIDE_MODAL,
});
