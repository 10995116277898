import React from 'react';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { formData } from '../../constants/formik_data';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
  },
}));

const FormikSample = React.forwardRef((props, ref) => {
  const { index = 0, data = null, submit } = props;
  const classes = useStyles();

  const handleSubmit = (values) => {
    submit(values);
  };

  const initialValues = {};
  formData[index].forEach((f) => {
    initialValues[f.name.input] = data !== null ? data[f.name.input] : '';
  });

  return (
    <Formik
      innerRef={ref}
      initialValues={initialValues}
      // validate={validate}
      onSubmit={(values, actions) => {
        handleSubmit(values);
      }}>
      {(props) => {
        const { values, handleChange, handleSubmit } = props;
        return (
          <Form className="uk-form-stacked" onSubmit={handleSubmit}>
            {formData[index].map((d, idx) => (
              <div key={idx} className="mb-3">
                <TextField
                  className={classes.input}
                  type={d.type}
                  name={d.name.input}
                  id={'outlined-basic-' + d.name.input}
                  size="small"
                  multiline={d.inputType === 'multiline'}
                  // rowsMax={6}
                  rows={4}
                  label={d.name.label}
                  value={values[d.name.input]}
                  onChange={handleChange}
                  // variant="outlined"
                  variant="filled"
                />
              </div>
            ))}
          </Form>
        );
      }}
    </Formik>
  );
});

export default FormikSample;
