import React, { useState } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Menu as MUIMenu, MenuItem, Fade, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  paper: {
    minWidth: 160,
    boxShadow:
      '0 10px 20px 0 rgba(117,99,99,0.11), 0 3px 6px 0 rgba(0,0,0,0.07)',
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const Component = ({
  items,
  className,
  'data-testid': testId,
  menuItemClassName = '',
  menuListClassName = '',
  renderTriggerComponent,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleItemClicked = (item) => {
    if (item.onClick) {
      item.onClick();
    }

    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRenderTriggerComponent = () => {
    if (renderTriggerComponent) {
      return renderTriggerComponent({ showMenu: handleClick });
    }

    return null;
  };

  const renderMenuItems = () => {
    const commonProps = {
      classes: {
        root: menuItemClassName,
      },
    };

    return items.map((item) => {
      return (
        <MenuItem
          {...commonProps}
          onClick={() => handleItemClicked(item)}
          key={item.text}>
          <Typography size="caption">
            {item.text}
          </Typography>
        </MenuItem>
      );
    });
  };

  return (
    <>
      {handleRenderTriggerComponent()}
      <MUIMenu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        TransitionComponent={Fade}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          classes: {
            root: classnames(classes.menuList, menuListClassName),
          },
        }}
        classes={{
          paper: classnames(classes.paper, className),
        }}
        disableAutoFocusItem
        data-testid={testId}>
        {renderMenuItems()}
      </MUIMenu>
    </>
  );
};

export const Menu = (props) => <Component {...props} />;

Menu.displayName = 'Menu';
