import 'whatwg-fetch';

import _ from './../constants';
import Auth from './../components/App/Auth';

export const makeRequest = async ({
  path,
  method = 'GET',
  params = {},
  contentType = 'application/json',
  formData,
  success,
  error,
  successMessage,
  errorMessage,
}) => {
  const options = {
    method: method,
    body: params,
    headers: {
      'Content-Type': contentType,
      'Cache-Control': 'no-cache, must-revalidate',
    },
    mode: 'cors',
    timeout: 5,
  };
  if (method === 'GET' || method === 'DELETE') delete options.body;
  if (method === 'GET' || method === 'DELETE')
    delete options.headers['Content-Type'];
  if (formData) delete options.headers['Content-Type'];

  const token = Auth.isAuth();
  if (token) options.headers['Authorization'] = `Bearer ${token}`;

  let response = await fetch(
    path.indexOf('http') === 0 ? path : _.SERVER_API + path,
    options
  )
    .then((response) => {
      if (response.status === 500) {
        return Promise.reject({
          status: 500,
          message: 'Something went wrong. Contact us for more information.',
        });
      }

      if (!response.ok) {
        // throw Error(response.statusText);
        return response.json().then((res) => Promise.reject(res));
      }

      // check if content type non json object => return plain text
      if (
        response.headers.get('content-type') &&
        response.headers.get('content-type').indexOf('application/json') === -1
      ) {
        return response.text();
      }

      return response.json();
    })
    .then((data) => {
      if (success) return success(data, successMessage);
      if (successMessage) return { data, success: successMessage };
      return data;
    })
    .catch((err) => {
      if (err && err.type === 'Unauthorized') return;
      if (error)
        error(
          err,
          err.status && err.status === 500 ? err.message : errorMessage
        );
      return {
        error: err.status && err.status === 500 ? err.message : errorMessage,
      };
    });

  return response;
};
