export const INIT_USERS = 'INIT_USERS';
export const actionInitUsers = ({ id }) => {
  return {
    type: INIT_USERS,
    payload: { id },
  };
};

export const DID_INIT_USERS = 'DID_INIT_USERS';
export const actionDidInitUsers = (data) => {
  return {
    type: DID_INIT_USERS,
    payload: data,
  };
};

export const ADD_USER = 'ADD_USER';
export const actionAddUser = (data) => {
  return {
    type: ADD_USER,
    payload: data,
  };
};

export const ADD_USER_AS_JSON = 'ADD_USER_AS_JSON';
export const actionAddUserAsJson = ({ id, data }) => {
  return {
    type: ADD_USER_AS_JSON,
    payload: { id, data },
  };
};

export const CREATE_USER = 'CREATE_USER';
export const actionCreateUser = ({ id, data, resolve = () => {} }) => {
  return {
    type: CREATE_USER,
    payload: { id, data, resolve },
  };
};

export const DELETE_USER = 'DELETE_USER';
export const actionDeleteUser = ({
  project_id,
  user_id,
  resolve = () => {},
}) => {
  return {
    type: DELETE_USER,
    payload: { project_id, user_id, resolve },
  };
};

export const DID_DELETE_USER = 'DID_DELETE_USER';
export const actionDidDeleteUser = ({ user_id }) => {
  return {
    type: DID_DELETE_USER,
    payload: { user_id },
  };
};

export const UPDATE_USER = 'UPDATE_USER';
export const actionUpdateUser = ({
  project_id,
  user_id,
  data,
  resolve = () => {},
}) => {
  return {
    type: UPDATE_USER,
    payload: { project_id, user_id, data, resolve },
  };
};

export const DID_UPDATE_USER = 'DID_UPDATE_USER';
export const actionDidUpdateUser = ({ newData, user_id }) => {
  return {
    type: DID_UPDATE_USER,
    payload: { newData, user_id },
  };
};

export const START_LOADING_SAVE_USER_AS_JSON =
  'START_LOADING_SAVE_USER_AS_JSON';
export const actionStartLoadingSaveUserAsJson = () => {
  return {
    type: START_LOADING_SAVE_USER_AS_JSON,
  };
};

export const STOP_LOADING_SAVE_USER_AS_JSON = 'STOP_LOADING_SAVE_USER_AS_JSON';
export const actionStopLoadingSaveUserAsJson = () => {
  return {
    type: STOP_LOADING_SAVE_USER_AS_JSON,
  };
};

export const START_LOADING_USERS = 'START_LOADING_USERS';
export const actionStartLoadingUsers = () => {
  return {
    type: START_LOADING_USERS,
  };
};

export const STOP_LOADING_USERS = 'STOP_LOADING_USERS';
export const actionStopLoadingUsers = () => {
  return {
    type: STOP_LOADING_USERS,
  };
};
