import update from 'immutability-helper';
import * as actions from '../actions';

const defaultWorkspaceState = {
  datasources: [],
  druidData: [],
  selectedDatasourceRow: null,
  loadingDatasource: 'regular',
  loadingDruidData: 'regular',
};

const initState = {
  version: "v0.1.7",
  projects: [],
  project: {},
  ...defaultWorkspaceState,
};

export default (state = initState, { type, payload }) => {
  switch (type) {
    case actions.DID_INIT_PROJECTS: {
      return {
        ...state,
        projects: payload,
      };
    }

    case actions.DID_INIT_PROJECT: {
      return {
        ...state,
        project: payload,
      };
    }

    case actions.DID_INIT_DATASOURCES: {
      return {
        ...state,
        datasources: payload,
      };
    }

    case actions.DID_UPDATE_PROJECT: {
      const idx_project = state.projects.findIndex((d) => d.id === payload.id);
      return {
        ...state,
        projects:
          idx_project !== -1
            ? update(state.projects, {
                [idx_project]: {
                  $set: { ...state.projects[idx_project], ...payload.data },
                },
              })
            : state.projects,
        project: update(state.project, {
          $set: { ...state.project, ...payload.data },
        }),
      };
    }

    case actions.SELECT_DATASOURCE_ROW: {
      return {
        ...state,
        selectedDatasourceRow: payload,
      };
    }

    case actions.DID_LOAD_DRUID_DATA: {
      return {
        ...state,
        druidData: payload.data,
      };
    }

    case 'START_LOADING_DATASOURCE': {
      return {
        ...state,
        loadingDatasource: 'saving',
      };
    }

    case 'STOP_LOADING_DATASOURCE': {
      return {
        ...state,
        loadingDatasource: 'regular',
      };
    }

    case actions.START_LOADING_DRUID_DATA: {
      return {
        ...state,
        loadingDruidData: 'saving',
      };
    }

    case actions.STOP_LOADING_DRUID_DATA: {
      return {
        ...state,
        loadingDruidData: 'regular',
      };
    }

    case actions.RESET_WORKSPACE_STATE: {
      return { ...state, ...defaultWorkspaceState };
    }

    default:
      return state;
  }
};
