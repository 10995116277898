import React from 'react';
import { Spinner } from '../spinner';

export function TableSpinner({ loading }) {
  return loading ? (
    <div
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: '0',
        zIndex: 1,
        opacity: 1,
        backgroundColor: 'hsla(0,0%,100%,.75)',
      }}>
      <div style={{ position: 'absolute', left: '50%', top: '100px' }}>
        <Spinner size={32} />
      </div>
    </div>
  ) : null;
}
