import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const Notification = (props) => {
  const {
    text,
    severity = 'success',
    open,
    duration,
    vertical,
    horizontal,
    actionHideNotification,
  } = props;
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={duration}
      onClose={actionHideNotification}>
      <Alert onClose={actionHideNotification} severity={severity}>
        {text}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
