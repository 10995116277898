import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5, 2),
    marginTop: 'auto',
    textAlign: 'center',
    paddingTop: '2.25rem'
  },
}));

function Footer({ version, backgroundColor }) {
  const classes = useStyles();

  return (
    <footer className={classes.root} style={{ backgroundColor }}>
      <Container maxWidth="sm">
        <Typography variant="body2">
          Queryable Admin {version}
        </Typography>
      </Container>
    </footer>
  );
}

const mapStateToProps = (state, props) => {
  return {
    ...state,
    version: state.data.version,
  };
};

export default connect(mapStateToProps, undefined)(Footer);
