import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Box from '@material-ui/core/Box';
import { TableSpinner } from '../../shared';

const useStyles = makeStyles((theme) => {
  return {
    tableContaner: {
      height: '100%',
      position: 'relative',
      minWidth: 200,
      overflowX: 'unset',
      overflowY: 'auto',
    },
    th: {
      position: 'sticky !important',
      top: '0px',
      backgroundColor: theme.palette.common.white,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    td: {
      padding: '0.5rem 1rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: 'inherit',
    },
    tr: {
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    controls: {
      position: 'sticky',
      right: '0px',
      backgroundColor: 'inherit',
      height: '100%',
    },
    search: {
      position: 'absolute',
      zIndex: 2,
    },
    loader: {
      '& > div > div': {
        top: '130px !important',
      },
    },
    loaderNoSearch: {
      '& > div > div': {
        top: '80px !important',
      },
    },
  };
});

function descendingComparator(a, b, sortField) {
  if (b[sortField] < a[sortField]) {
    return -1;
  }
  if (b[sortField] > a[sortField]) {
    return 1;
  }
  return 0;
}

function getComparator(sortType, sortField) {
  return sortType === 'desc'
    ? (a, b) => descendingComparator(a, b, sortField)
    : (a, b) => -descendingComparator(a, b, sortField);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const sortType = comparator(a[0], b[0]);
    if (sortType !== 0) return sortType;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function SimpleMaterialTable({
  data,
  columns,
  className,
  rowsProps,
  isSearch = true,
  isSort = true,
  tableContainerProps,
  searchFieldProps,
  tableRef,
  loading,
}) {
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState('');
  const [sortType, setSortType] = useState('asc');
  const [sortField, setSortField] = useState('');

  const createSortHandler = (property) => (event) => {
    const isAsc = sortField === property && sortType === 'asc';
    setSortType(isAsc ? 'desc' : 'asc');
    setSortField(property);
  };

  return (
    <Box className={className} p={0.5} ref={tableRef}>
      {isSearch ? (
        <Box mb={1} pb={6} {...searchFieldProps}>
          <TextField
            id="table-search"
            label="Search"
            className={classes.search}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            size="small"
          />
        </Box>
      ) : (
        ''
      )}
      <TableContainer
        className={classes.tableContaner}
        {...tableContainerProps}
      >
        <Table size="small" aria-label="simple material table" stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((col, i) => (
                <TableCell
                  className={classes.th}
                  key={`header-cell-${i}`}
                  id={`header-cell-${col.field}`}
                  {...(col.props ? { ...col.props } : {})}
                >
                  {isSort && col.title ? (
                    <TableSortLabel
                      active={sortField === col.field}
                      direction={sortField === col.field ? sortType : 'asc'}
                      onClick={createSortHandler(col.field)}
                    >
                      {col.title}
                    </TableSortLabel>
                  ) : (
                    col.title
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(
              searchValue
                ? data.filter((d) =>
                    columns.find(
                      (c) =>
                        String(d[c.field])
                          .toLowerCase()
                          .indexOf(searchValue.toLowerCase()) > -1
                    )
                  )
                : data,
              getComparator(sortType, sortField)
            ).map((el, ind) => (
              <TableRow
                key={`row-${ind}`}
                id={`row-${ind}`}
                {...rowsProps(el, ind)}
                className={classes.tr}
              >
                {columns.map((col, i) => (
                  <TableCell
                    className={classes.td}
                    key={`body-cell-${ind}-${i}`}
                    id={`cell-${el.datasource_id}`}
                    {...(col.props ? { ...col.props } : {})}
                  >
                    {col.component ? col.component(el) : el[col.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={isSearch ? classes.loader : classes.loaderNoSearch}>
        <TableSpinner loading={loading} />
      </Box>
    </Box>
  );
}
