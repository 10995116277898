import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { actionInitProjects } from '../../store/actions';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Header from '../Other/Header';
import Footer from '../Other/Footer';

import './Projects.scss';

function Projects({ projects, actionInitProjects }) {
  let history = useHistory();

  useEffect(() => {
    if (!projects.length) {
      actionInitProjects();
    }
  }, []);
  console.log('+++', projects);
  return (
    <>
      <Header />
      <section className="pt-4 pb-4 projects-page">
        <div className="container">
          <div className="row d-flex flex-wrap">
            {projects.map((pr) => (
              <div key={pr.id} className="col-md-3 pb-4">
                <Card
                  className="card-class"
                  onClick={() => history.push(`/projects/${pr.id}`)}>
                  <CardHeader
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title={
                      <div>
                        <h6>Workbook</h6>
                      </div>
                    }
                  />
                  <CardContent>
                    <div className="d-flex justify-content-center">
                      <h4>{pr.title}</h4>
                    </div>
                  </CardContent>
                  <CardContent>
                    <div className="text-center">
                      <i>{pr.description}</i>
                    </div>
                  </CardContent>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

const mapStateToProps = (state, props) => {
  const { projects } = state.data;
  return {
    ...state,
    projects: projects || [],
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionInitProjects: () => dispatch(actionInitProjects()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
