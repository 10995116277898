import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import _v from '../../styles/_variables.scss';

const _links = [
  {
    label: 'Config',
    path: 'config',
  },
  {
    label: 'Data Sources',
    path: 'datasources',
  },
  {
    label: 'Users',
    path: 'users',
  },
];

const useStyles = makeStyles((theme) => ({
  sidebar: {
    position: 'fixed',
    width: _v.sidebarWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    height: '100%',
    backgroundColor: _v.light_color,
  },
  list: {
    padding: 0,
  },
  item: {
    height: '5rem',
    borderBottom: '1px solid #00000014',
  },
  listText: {
    whiteSpace: 'normal',
    textAlign: 'center',
  },
}));

export default function Sidebar() {
  const classes = useStyles();
  const history = useHistory();
  let location = useLocation().pathname.split('/');
  location = location[location.length - 1];
  const { url } = useRouteMatch();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const idx = _links.findIndex((d) => d.path === location);
    if (idx !== -1) {
      setActiveTab(idx);
    }
  }, []);

  return (
    <div className={classes.sidebar}>
      <List className={classes.list}>
        {_links.map((d, i) => (
          <ListItem
            button
            className={classes.item}
            selected={activeTab === i}
            key={d.label}
            onClick={() => {
              setActiveTab(i);
              history.push(`${url}/${d.path}`);
            }}>
            <ListItemText className={classes.listText} primary={d.label} />
          </ListItem>
        ))}
      </List>
    </div>
  );
}
