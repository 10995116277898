import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    width: '100%',
  },
  paper: {
    width: '100%',
    padding: '.5rem',
  },
}));

export default function PaperSample({ children, style }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={2} className={classes.paper} style={style ? style : {}}>
        {children}
      </Paper>
    </div>
  );
}
