import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { useNotification } from '../../services/hooks';

import { ThemeProvider } from '../../shared';

import Login from '../Pages/Login';
import Projects from '../Pages/Projects';
import Project from '../Pages/Project';

import Auth from './Auth';
import NotificationContainer from '../Notification/NotificationContainer';
import ModalContainer from '../Modals/ModalContainer';

import './App.scss';

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        Auth.isAuth() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const LoginPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { notificationError } = useNotification();

  const { from } = location.state || { from: { pathname: '/' } };
  const login = (e, data) =>
    Auth.login(
      data,
      () => history.push(from),
      (message) => notificationError(message)
    );

  return <Login login={login} />;
};

function App({ store }) {
  return (
    <ThemeProvider>
      <Provider store={store}>
        <SnackbarProvider
          maxSnack={1}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <Router>
            <div className="row m-0 quaryable-admin h-100">
              <div className="col col-12 p-0 d-flex flex-column h-100">
                <Switch>
                  <Route path="/login">
                    <LoginPage />
                  </Route>
                  <Redirect exact from="/" to="/projects" />
                  <PrivateRoute exact path="/projects">
                    <Projects />
                  </PrivateRoute>
                  <PrivateRoute path="/projects/:id">
                    <Project />
                  </PrivateRoute>
                </Switch>
              </div>
            </div>
            <NotificationContainer />
            <ModalContainer />
          </Router>
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
