import React, { useCallback, useState, useEffect } from 'react';
import { useAsyncState } from '../../services/hooks';
import { connect } from 'react-redux';
import {
  actionInitDatasources,
  actionDeleteDatasource,
  actionAddDatasource,
  actionUpdateDatasource,
  actionSelectDatasourceRow,
} from '../../store/actions';
import { actionShowModal } from '../Modals/actions';
import { Box, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ModalTypes } from '../Modals/constants';

import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';

import Title from '../Other/Title';
import ConfigColumn from './datasourcesComponents/ConfigColumn';
import BottomEditor from './datasourcesComponents/BottomEditor';
import DatasourcesTable from './datasourcesComponents/DatasourcesTable';
import { Menu } from '../../shared';
import DruidTable from './datasourcesComponents/DruidTable';

import _dataTable from '../../constants/datasource_table_data.json';
import datasource_default from '../../constants/datasource_default.json';

const useStyles = makeStyles((theme) => ({
  addDatasource: {
    display: 'flex',
    position: 'absolute',
    top: '2px',
    right: '-8px',
    zIndex: '10',
  },
  modalClassName: {
    width: '800px',
  },
}));

const Datasources = ({
  project,
  datasources,
  selectedDatasourceRow,
  loadingDatasource,
  actionShowModal,
  actionInitDatasources,
  actionDeleteDatasource,
  actionAddDatasource,
  actionUpdateDatasource,
  actionSelectDatasourceRow,
}) => {
  const classes = useStyles();
  const loading = loadingDatasource;

  const [indexes, setIndexes] = useAsyncState({
    datasource: null,
    dataset: null,
  });

  useEffect(() => {
    actionInitDatasources({ id: project.id });
  }, []);

  const handleSetIndexes = (_data, rowData, tableName) => {
    if (tableName === 'datasource') {
      const idx = _data.findIndex(
        (d) => d.tableData.id === rowData.tableData.id
      );
      setIndexes({ datasource: idx, dataset: null });
    } else if (tableName === 'dataset') {
      const idx = _data.findIndex(
        (d) => d.tableData.id === rowData.tableData.id
      );
      setIndexes((old) => ({ ...old, dataset: idx }));
    }
  };

  const handleDeleteDatasource = useCallback(
    (id) => {
      actionDeleteDatasource({
        id: project.id,
        datasource_id: id,
      });
    },
    [datasources, project]
  );

  const handleAddDatasource = useCallback(
    (json) => {
      actionAddDatasource({
        id: project.id,
        data: json,
      });
    },
    [datasources, project]
  );

  const handleUpdateDatasource = (data, datasource_id) => {
    actionUpdateDatasource({
      id: project.id,
      datasource_id: datasource_id || selectedDatasourceRow.datasource_id,
      data: data,
    });
  };

  const handleRefresh = () => {
    actionInitDatasources({ id: project.id });
  };

  return (
    <Box>
      <Grid container spacing={6}>
        <Grid item container spacing={5}>
          <Grid item xs={12}>
            <Box>
              <Title title="Data Source List" />
            </Box>
            <Box position="relative">
              <Box className={classes.addDatasource}>
                <IconButton onClick={handleRefresh}>
                  <CachedIcon />
                </IconButton>
                <Menu
                  items={[
                    {
                      text: 'Copy from Current',
                      onClick: () =>
                        actionShowModal(ModalTypes.AddDatasourceModal, {
                          modalClassName: classes.modalClassName,
                          modalConfig: {
                            handleAddDatasource,
                            datasource:
                              selectedDatasourceRow || datasource_default,
                          },
                        }),
                    },
                    {
                      text: 'Add New',
                      onClick: () =>
                        actionShowModal(ModalTypes.AddDatasourceModal, {
                          modalClassName: classes.modalClassName,
                          modalConfig: {
                            handleAddDatasource,
                            datasource: datasource_default,
                          },
                        }),
                    },
                  ].filter(Boolean)}
                  renderTriggerComponent={({ showMenu }) => (
                    <div style={{ display: 'flex' }}>
                      <IconButton onClick={showMenu}>
                        <AddIcon />
                      </IconButton>
                    </div>
                  )}
                  data-testid={'add-datasource-menu'}
                />
              </Box>
              <DatasourcesTable
                data={datasources}
                selectedDatasourceRow={selectedDatasourceRow}
                handleSelectDatasourceRow={actionSelectDatasourceRow}
                handleDeleteDatasource={handleDeleteDatasource}
                handleUpdateDatasource={handleUpdateDatasource}
                loading={loading}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Title title="Config" />
            </Box>
            <ConfigColumn
              data={
                selectedDatasourceRow
                  ? datasources.find(
                      (el) =>
                        el.datasource_id === selectedDatasourceRow.datasource_id
                    ).config
                  : {}
              }
              handleSave={handleUpdateDatasource}
              name="config"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Title title="Default user config" />
            </Box>
            <ConfigColumn
              data={
                selectedDatasourceRow
                  ? datasources.find(
                      (el) =>
                        el.datasource_id === selectedDatasourceRow.datasource_id
                    ).default_user_config || {}
                  : {}
              }
              handleSave={handleUpdateDatasource}
              name="default_user_config"
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            {/* <Title title="Data Source Schema" />
            <BottomEditor /> */}
            <Title title="Table Data" />
            <DruidTable />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state, props) => {
  const {
    project,
    datasources,
    selectedDatasourceRow,
    loadingDatasource,
  } = state.data;
  return {
    ...state,
    project: project || {},
    datasources: datasources || [],
    selectedDatasourceRow: selectedDatasourceRow || null,
    loadingDatasource: loadingDatasource === 'saving',
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionInitDatasources: (data) => dispatch(actionInitDatasources(data)),
    actionDeleteDatasource: (data) => dispatch(actionDeleteDatasource(data)),
    actionAddDatasource: (data) => dispatch(actionAddDatasource(data)),
    actionUpdateDatasource: (data) => dispatch(actionUpdateDatasource(data)),
    actionShowModal: (modalType, config) =>
      dispatch(actionShowModal(modalType, config)),
    actionSelectDatasourceRow: (data) =>
      dispatch(actionSelectDatasourceRow(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Datasources);

const datasetsTemplate = (index) => {
  return [
    {
      name: `dataset ${index}_1`,
      config: {
        some: `Some dataset 1`,
      },
      metrics: ['metric 1_1', 'metric 2', 'metric 3'],
      columns: ['column 1', 'column 2', 'column 3'],
      dates: ['12-23-2004', '12-23-1999', '12-23-1993', '5-12-2001'],
    },
    {
      name: `dataset ${index}_2`,
      config: {
        some: 'Some dataset 2',
      },
      metrics: ['metric 2_1', 'metric 2', 'metric 3'],
      columns: ['column 1', 'column 2', 'column 3'],
      dates: ['12-23-2004', '12-23-1999', '12-23-1993', '5-12-2001'],
    },
    {
      name: `dataset ${index}_3`,
      config: {
        some: 'Some dataset 3',
      },
      metrics: ['metric 3_1', 'metric 2', 'metric 3'],
      columns: ['column 1', 'column 2', 'column 3'],
      dates: ['12-23-2004', '12-23-1999', '12-23-1993', '5-12-2001'],
    },
  ];
};
