import { ModalOptions } from './constants';
import * as actions from './actions';

const defaultModalState = {
  open: false,
  type: undefined,
  config: {},
};

export default function modalReducer(state = defaultModalState, action) {
  const { type, payload } = action;
  switch (type) {
    case actions.SHOW_MODAL: {
      return {
        ...state,
        type: payload.modalType,
        open: true,
        config: { ...ModalOptions[payload.modalType], ...payload.config },
      };
    }

    case actions.HIDE_MODAL:
      return defaultModalState;

    default:
      return state;
  }
}
