import React, { createRef, useState } from 'react';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { actionUpdateProject } from '../../store/actions';

import Title from '../Other/Title';
import JsonEditor from '../Other/JsonEditor';
import FormikSample from '../Other/FormikSample';
import PaperSample from '../Other/PaperSample';

const Config = ({ project, actionUpdateProject }) => {
  const __json = project.config || {};
  const formikRef = createRef();
  const [json, setJson] = useState(__json);

  const handleSave = (values) => {
    const newObj = {
      ...values,
      config: json,
    };
    actionUpdateProject({ id: project.id, data: newObj });
  };

  const handleJSON = (_json) => {
    setJson(_json);
  };

  return (
    <>
      <Title title="Common App Config" rowStyle={{ margin: 0 }} />
      <div className="row mt-3">
        <div className="col-md-6 pb-4">
          <PaperSample>
            <JsonEditor data={__json} searchData={json} handleJSON={handleJSON} />
          </PaperSample>
        </div>
        <div className="col-md-6 d-flex flex-column">
          <div className="row justify-content-center h-100 flex-grow-1">
            <div className="col-md-8">
              <FormikSample
                ref={formikRef}
                index={0} // from constans/formik_data.js
                data={project}
                submit={handleSave}
              />
            </div>
          </div>
          <div className="row flex-grow-0">
            <div className="col-md-12 d-flex justify-content-end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => formikRef.current.submitForm()}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, props) => {
  const { project } = state.data;
  return {
    ...state,
    project: project || {},
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionUpdateProject: (id, data) => dispatch(actionUpdateProject(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Config);
