export const INIT_PROJECTS = 'INIT_PROJECTS';
export const actionInitProjects = () => {
  return {
    type: INIT_PROJECTS,
  };
};

export const DID_INIT_PROJECTS = 'DID_INIT_PROJECTS';
export const actionDidInitProjects = (data) => {
  return {
    type: DID_INIT_PROJECTS,
    payload: data,
  };
};

export const INIT_PROJECT = 'INIT_PROJECT';
export const actionInitProject = (id) => {
  return {
    type: INIT_PROJECT,
    payload: { id },
  };
};

export const DID_INIT_PROJECT = 'DID_INIT_PROJECT';
export const actionDidInitProject = (data) => {
  return {
    type: DID_INIT_PROJECT,
    payload: data,
  };
};

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const actionUpdateProject = ({ id, data }) => {
  return {
    type: UPDATE_PROJECT,
    payload: { id, data },
  };
};

export const DID_UPDATE_PROJECT = 'DID_UPDATE_PROJECT';
export const actionDidUpdateProject = (id, data) => {
  return {
    type: DID_UPDATE_PROJECT,
    payload: { id, data },
  };
};

export const INIT_DATASOURCES = 'INIT_DATASOURCES';
export const actionInitDatasources = ({ id }) => {
  return {
    type: INIT_DATASOURCES,
    payload: { id },
  };
};

export const DID_INIT_DATASOURCES = 'DID_INIT_DATASOURCES';
export const actionDidInitDatasources = (data) => {
  return {
    type: DID_INIT_DATASOURCES,
    payload: data,
  };
};

export const SELECT_DATASOURCE_ROW = 'SELECT_DATASOURCE_ROW';
export const actionSelectDatasourceRow = (data) => {
  return {
    type: SELECT_DATASOURCE_ROW,
    payload: data,
  };
};

export const DELETE_DATASOURCE = 'DELETE_DATASOURCE';
export const actionDeleteDatasource = ({ id, datasource_id }) => {
  return {
    type: DELETE_DATASOURCE,
    payload: { id, datasource_id },
  };
};

export const ADD_DATASOURCE = 'ADD_DATASOURCE';
export const actionAddDatasource = ({ id, data }) => {
  return {
    type: ADD_DATASOURCE,
    payload: { id, data },
  };
};

export const UPDATE_DATASOURCE = 'UPDATE_DATASOURCE';
export const actionUpdateDatasource = ({ id, datasource_id, data }) => {
  return {
    type: UPDATE_DATASOURCE,
    payload: { id, datasource_id, data },
  };
};

export const LOAD_DRUID_DATA = 'LOAD_DRUID_DATA';
export const actionLoadDruidData = ({ id, datasource_id, data }) => {
  return {
    type: LOAD_DRUID_DATA,
    payload: { id, datasource_id, data },
  };
};

export const DID_LOAD_DRUID_DATA = 'DID_LOAD_DRUID_DATA';
export const actionDidLoadDruidData = (data) => {
  return {
    type: DID_LOAD_DRUID_DATA,
    payload: { data },
  };
};

export const actionStartLoadingDatasource = () => {
  return {
    type: 'START_LOADING_DATASOURCE',
  };
};

export const actionStopLoadingDatasource = () => {
  return {
    type: 'STOP_LOADING_DATASOURCE',
  };
};

export const START_LOADING_DRUID_DATA = 'START_LOADING_DRUID_DATA';
export const actionStartLoadingDruidData = () => {
  return {
    type: START_LOADING_DRUID_DATA,
  };
};

export const STOP_LOADING_DRUID_DATA = 'STOP_LOADING_DRUID_DATA';
export const actionStopLoadingDruidData = () => {
  return {
    type: STOP_LOADING_DRUID_DATA,
  };
};

export const RESET_WORKSPACE_STATE = 'RESET_WORKSPACE_STATE';
export const actionResetWorkspaceState = () => {
  return {
    type: RESET_WORKSPACE_STATE,
  };
};
