import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';

import JsonEditor from '../../Other/JsonEditor';
import PaperSample from '../../Other/PaperSample';
import SimpleContainer from './SimpleContainer';

const _json = {};

const ConfigColumn = ({ data = {}, name, handleSave }) => {
  const [newJSON, setNewJSON] = useState(data);
  const handleChangeJSON = (json) => {
    setNewJSON(json);
  };

  useEffect(() => {
    setNewJSON(data)
  }, [data])

  return (
    <SimpleContainer>
      <PaperSample style={{ position: 'relative' }}>
        <Button
          variant="contained"
          color="primary"
          style={{ position: 'absolute', zIndex: 100, right: 15, top: -50 }}
          onClick={() => handleSave({ [name]: newJSON })}>
          Save
        </Button>
        <JsonEditor
          data={data || _json}          
          searchData={newJSON}
          handleJSON={handleChangeJSON}
          height="21rem"
        />
      </PaperSample>
    </SimpleContainer>
  );
};

export default ConfigColumn;
