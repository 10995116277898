import * as actions from './actions';
import update from 'immutability-helper';

const initState = {
  users: [],
  loadingSaveUserAsJson: 'regular',
  loadingUsers: 'regular',
};

export default (state = initState, { type, payload }) => {
  switch (type) {
    case actions.DID_INIT_USERS: {
      return {
        ...state,
        users: payload,
      };
    }

    case actions.ADD_USER: {
      const checkType = Array.isArray(payload);
      return {
        ...state,
        users: update(
          state.users,
          !checkType
            ? { $push: [payload] }
            : { $set: [...state.users, ...payload] }
        ),
      };
    }

    case actions.DID_DELETE_USER: {
      const idx_user = state.users.findIndex(
        (d) => d.user_id === payload.user_id
      );
      return {
        ...state,
        users:
          idx_user !== -1
            ? update(state.users, {
                $splice: [[idx_user, 1]],
              })
            : state.users,
      };
    }

    case actions.DID_UPDATE_USER: {
      const idx_user = state.users.findIndex(
        (d) => d.user_id === payload.user_id
      );
      return {
        ...state,
        users:
          idx_user !== -1
            ? update(state.users, {
                [idx_user]: {
                  full_name: { $set: payload.newData.full_name },
                  email: { $set: payload.newData.email },
                },
              })
            : state.users,
      };
    }

    case actions.START_LOADING_SAVE_USER_AS_JSON: {
      return {
        ...state,
        loadingSaveUserAsJson: 'saving',
      };
    }

    case actions.STOP_LOADING_SAVE_USER_AS_JSON: {
      return {
        ...state,
        loadingSaveUserAsJson: 'regular',
      };
    }

    case actions.START_LOADING_USERS: {
      return {
        ...state,
        loadingUsers: 'saving',
      };
    }

    case actions.STOP_LOADING_USERS: {
      return {
        ...state,
        loadingUsers: 'regular',
      };
    }

    default:
      return state;
  }
};
