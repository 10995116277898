import { all, takeEvery, call, put, select, delay } from 'redux-saga/effects';
import * as actions from './actions';
import { actionHideModal } from '../Modals/actions';
import * as api from '../../services/api';
import { actionShowNotification } from '../Notification/actions';

function* initUsers() {
  yield takeEvery(actions.INIT_USERS, function* ({ payload: { id } }) {
    console.log('SAGA: init users');

    yield put(actions.actionStartLoadingUsers())
    const users = yield call(api.getUsers, { id });

    if (users && !users.error) {
      yield put(actions.actionDidInitUsers(users));
    } else {
      console.log('???', users.error);
      yield put(actionShowNotification(users.error, 'error'));
    }
    yield put(actions.actionStopLoadingUsers());
  });
}

function* addUserAsJson() {
  yield takeEvery(
    actions.ADD_USER_AS_JSON,
    function* ({ payload: { id, data } }) {
      console.log('SAGA: add user(s) as json');

      yield put(actions.actionStartLoadingSaveUserAsJson());
      yield delay(10);
      const checkType = Array.isArray(data);
      const user = yield call(
        api[!checkType ? 'createUser' : 'createMultipleUser'],
        {
          id,
          data,
        }
      );

      if (user && !user.error) {
        yield put(actions.actionAddUser(user));
        yield put(actions.actionStopLoadingSaveUserAsJson());
        yield put(actionHideModal());
      } else {
        console.log('???', user.error);
        yield put(actionShowNotification(user.error, 'error'));
        yield put(actions.actionStopLoadingSaveUserAsJson());
      }
    }
  );
}

function* createUser() {
  yield takeEvery(
    actions.CREATE_USER,
    function* ({ payload: { id, data, resolve } }) {
      console.log('SAGA: create user');

      const user = yield call(api.createUser, {
        id,
        data: { email: 'test', password: 'test' },
      });

      if (user && !user.error) {
        const users = yield select((state) => state.users.users);
        yield put(actions.actionDidInitUsers([...users, user]));
        resolve();
      } else {
        console.log('???', user.error);
        yield put(actionShowNotification(user.error, 'error'));
        resolve();
      }
    }
  );
}

function* updateUser() {
  yield takeEvery(
    actions.UPDATE_USER,
    function* ({ payload: { project_id, user_id, data, resolve } }) {
      console.log('SAGA: update user');

      const user = yield call(api.updateUser, {
        project_id,
        user_id,
        data: { email: data.email, full_name: data.full_name },
      });

      if (user && !user.error) {
        yield put(actions.actionDidUpdateUser({ newData: data, user_id }));
        resolve();
      } else {
        console.log('???', user.error);
        yield put(actionShowNotification(user.error, 'error'));
        resolve();
      }
    }
  );
}

function* deleteUser() {
  yield takeEvery(
    actions.DELETE_USER,
    function* ({ payload: { project_id, user_id, resolve } }) {
      console.log('SAGA: delete user');

      const user = yield call(api.deleteUser, { project_id, user_id });

      if (user && !user.error) {
        yield put(actions.actionDidDeleteUser({ user_id }));
        resolve();
      } else {
        console.log('???', user.error);
        yield put(actionShowNotification(user.error, 'error'));
        resolve();
      }
    }
  );
}

export default function* watchUsersSaga() {
  yield all([
    initUsers(),
    addUserAsJson(),
    updateUser(),
    deleteUser(),
    createUser(),
  ]);
}
