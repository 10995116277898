export const NotificationActions = {
  SHOW_APPLICATION_NOTIFICATION: 'notification/SHOW_APPLICATION_NOTIFICATION',
  HIDE_APPLICATION_NOTIFICATION: 'notification/HIDE_APPLICATION_NOTIFICATION',
};

export const actionShowNotification = (
  text,
  severity,
  duration,
  vertical,
  horizontal
) => ({
  type: NotificationActions.SHOW_APPLICATION_NOTIFICATION,
  payload: { text, severity, duration, vertical, horizontal },
});

export const actionHideNotification = () => ({
  type: NotificationActions.HIDE_APPLICATION_NOTIFICATION,
});
