import React, { useRef, useEffect, useState } from 'react';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import 'brace/mode/json';

import {
  TextField,
  Box,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { colors } from '../../shared/theme/theme';

import './JsonEditor.scss';

const SearchField = withStyles((theme) => ({
  root: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    width: 150,

    '& input': {
      padding: 5,
      transform: 'translate(4px, 0px) scale(1)',
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => {
  return {
    found: {
      backgroundColor: colors.lightBlue,
    },
    searchBox: {
      position: 'absolute',
      top: 3,
      right: 3,
      zIndex: 10,
      display: 'flex',

      '& p': {
        color: theme.palette.common.white,
      },
    },
    higher: {
      position: 'absolute',
      top: 32,
      right: 10,
      zIndex: 10,
      color: colors.lightBlue,
    },
    below: {
      position: 'absolute',
      bottom: 22,
      right: 10,
      zIndex: 10,
      color: colors.lightBlue,
    },
    map: {
      position: 'absolute',
      top: 36,
      right: -5,
      width: 20,
      zIndex: 9,

      '& div': {
        position: 'absolute',
        backgroundColor: colors.lightBlue,
        width: 6,
        height: 6,
        borderRadius: '100%',
      },
    },
  };
});

const findLine = (params, visionRange) => {
  params.count++;

  params.foundLines.push(params.linesCount);

  if (!params.higher && visionRange && params.linesCount < visionRange[0])
    params.higher = true;
  if (!params.below && visionRange && params.linesCount > visionRange[1])
    params.below = true;
};

const searching = async ({
  search,
  setFind,
  id,
  classes,
  data,
  lineHeight = 16,
}) => {
  const block = document.getElementById(id);
  if (block) {
    const variables = block.getElementsByClassName('ace_variable');
    const strings = block.getElementsByClassName('ace_string');
    const numerics = block.getElementsByClassName('ace_numeric');
    const boolean = block.getElementsByClassName('ace_boolean  ');
    const height =
      block.getElementsByClassName('ace_scroller')[0].clientHeight - 5;

    const lines = block.getElementsByClassName('ace_gutter-cell');
    let visionRange;

    setTimeout(() => {
      visionRange = [+lines[0].innerText, +lines[lines.length - 1].innerText];

      [variables, strings, numerics, boolean].forEach((el, i) => {
        for (let i = 0; i < el.length; i++) {
          if (
            search &&
            el[i].innerText.toLowerCase().indexOf(search.toLowerCase()) >= 0
          ) {
            el[i].classList.add(classes.found);
          } else {
            el[i].classList.remove(classes.found);
          }
        }
      });

      if (search) {
        const params = {
          count: 0,
          higher: false,
          below: false,
          linesCount: 0,
          foundLines: [],
        };

        JSON.stringify(data, (key, value, a) => {
          params.linesCount++;

          if (key.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
            findLine(params, visionRange);
          }
          if (
            (typeof value === 'string' ||
              typeof value === 'boolean' ||
              typeof value === 'number') &&
            String(value).toLowerCase().indexOf(search.toLowerCase()) >= 0
          ) {
            findLine(params, visionRange);
          }

          if (
            typeof value === 'object' &&
            !(Array.isArray(value) && !value.length) &&
            value !== null &&
            Object.keys(value).length
          ) {
            params.linesCount++;
          }

          return value;
        });

        const isScrolled = params.linesCount * lineHeight > height;

        setFind({
          ...params,
          higher: isScrolled ? params.higher : false,
          below: isScrolled ? params.below : false,
          foundLines: isScrolled
            ? params.foundLines.map(
                (el) =>
                  ((lineHeight * el) / (params.linesCount * lineHeight)) *
                  height
              )
            : [],
        });
      }
    }, 10);
  }
};

const SearchingControls = ({ find, classes }) => (
  <>
    {find.foundLines ? (
      <div className={classes.map}>
        {find.foundLines.map((el, i) => (
          <div
            key={i}
            style={{
              top: el,
            }}></div>
        ))}
      </div>
    ) : (
      ''
    )}
    {find.higher ? (
      <ArrowDropUpIcon fontSize="large" className={classes.higher} />
    ) : (
      ''
    )}
    {find.below ? (
      <ArrowDropDownIcon fontSize="large" className={classes.below} />
    ) : (
      ''
    )}
  </>
);

const JsonEditor = ({
  data = {},
  searchData,
  handleJSON,
  height,
  style,
  id = 'editor',
}) => {
  const jsonEditorRef = useRef(null);
  const [search, setSearch] = useState('');
  const [find, setFind] = useState({
    count: 0,
    linesMap: null,
    higher: false,
    below: false,
  });

  const classes = useStyles();

  const _id = jsonEditorRef.current ? jsonEditorRef.current.aceEditor.id : id;

  const searchСall = jsonEditorRef.current
    ? () =>
        searching({
          search,
          setFind,
          id: _id,
          classes,
          data: searchData || data,
        })
    : () => '';

  useEffect(() => {
    if (jsonEditorRef.current !== null) {
      jsonEditorRef.current.set(data);
    }
  }, [data]);

  const setRef = (instance) => {
    if (instance) {
      jsonEditorRef.current = instance.jsonEditor;
    } else {
      jsonEditorRef.current = null;
    }
  };

  useEffect(() => {
    searchСall();
  }, [search]);

  useEffect(() => {
    setTimeout(() => {
      searchСall();
    }, 1000);
  }, [searchData, data]);

  return (
    <Box position="relative" id={_id} onScroll={(e) => searchСall()}>
      <Box className={classes.searchBox}>
        {search ? (
          <Box mt={0.5} mr={1}>
            <Typography variant="body2">{find.count}</Typography>
          </Box>
        ) : (
          ''
        )}
        <SearchField
          size="small"
          placeholder="Search"
          variant="outlined"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <Editor
        ref={setRef}
        value={data}
        onChange={(e) => handleJSON(e)}
        //search={false}
        statusBar={true}
        //navigationBar={true}
        mode="code"
        ace={ace}
        //theme="brace/theme/github"
        //allowModes={['text', 'tree']}
        htmlElementProps={{
          style: {
            ...style,
            height: height ? height : 500,
          },
        }}
      />
      {search ? <SearchingControls find={find} classes={classes} /> : ''}
    </Box>
  );
};

export default JsonEditor;
