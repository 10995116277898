import React from 'react';

import './Title.scss';

export default function Title({ title, rowStyle = {} }) {
  return (
    <div className="row" style={rowStyle || {}}>
      <div className="col-md-12 px-1">
        <div className="title">
          <h5 className="m-0 mb-2">{title}</h5>
        </div>
      </div>
    </div>
  );
}
