import React from 'react';
import { connect } from 'react-redux';
import Notification from './Notification';
import { actionHideNotification } from './actions';

export const NotificationContainer = (props) => {
  return <Notification {...props} />;
};

const mapStateToProps = (state, props) => state.notification;

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionHideNotification: () => dispatch(actionHideNotification()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationContainer);
