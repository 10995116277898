import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme();

export const colors = {
  primary: {
    main: '#607d8b',
    light: '#698796',
    dark: '#58727e',
  },
  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
  },
  warning: {
    light: '#ffb74d',
    main: '#ff9800',
    dark: '#f57c00',
  },
  success: {
    light: '#81c784',
    main: '#4caf50',
    dark: '#388e3c',
  },
  lightBlue: "#a3bac5a1",
  info: {
    light: '#64b5f6',
    main: '#2196f3',
    dark: '#1976d2',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)'
  },
};

export const spacing = {
  gutter: defaultTheme.spacing(1),
  gutterLarge: defaultTheme.spacing(2),
};

export const customTheme = createMuiTheme({
  palette: {
    primary: colors.primary,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});
