import { combineReducers } from 'redux';
import dataReducer from './data';
import notificationReducer from '../../components/Notification/reducer';
import modalReducer from '../../components/Modals/reducer';
import usersReducer from '../../components/Users/reducer';

const reducers = combineReducers({
  data: dataReducer,
  notification: notificationReducer,
  modal: modalReducer,
  users: usersReducer
});

export default reducers;
