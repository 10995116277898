import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PaperSample from '../../Other/PaperSample';
import SimpleContainer from './SimpleContainer';
import { Table } from '../../../shared';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Tooltip, Typography } from '@material-ui/core';
import { actionLoadDruidData } from '../../../store/actions';

const useStyles = makeStyles((theme) => ({
  cellRoot: {
    maxWidth: '17rem',
  },
}));

export function DruidTable({
  druidData,
  loadingDruidData,
  actionLoadDruidData,
  id,
  datasource_id,
}) {
  const classes = useStyles();
  const [numberOfRows, setNumberOfRows] = useState(10);
  const [dataQueryString, setDataQueryString] = useState('{}');

  const handleChangeNumberOfRows = useCallback((value) => {
    let _value = '';
    if (!value || typeof +value !== 'number') {
      _value = '';
    } else {
      _value = +value < 0 ? 0 : +value;
    }

    setNumberOfRows(_value);
  });

  return (
    <SimpleContainer>
      <PaperSample style={{ position: 'relative', padding: '.5rem 0rem' }}>
        <Box mb={1} ml={2} display="flex" alignItems="center">
          <TextField
            id="number_of_rows"
            label="Number of rows"
            type="number"
            size="small"
            value={numberOfRows}
            onChange={(e) => handleChangeNumberOfRows(e.target.value)}
            onBlur={() => {
              if (!numberOfRows) setNumberOfRows(10);
            }}
          />
          <TextField
            id="data_query"
            label="Data Query"
            type="string"
            size="small"
            value={dataQueryString}
            onChange={(e) => setDataQueryString(e.target.value)}
            onBlur={() => {
              if (!dataQueryString) setDataQueryString('{}');
            }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: '1rem' }}
            disabled={loadingDruidData}
            onClick={() =>
              actionLoadDruidData(
                id,
                datasource_id,
                numberOfRows,
                dataQueryString
              )
            }>
            Load Data
          </Button>
        </Box>
        {druidData.length ? (
          <Table
            loading={loadingDruidData}
            headerRow={Object.keys(druidData[0]).map((d) => ({
              id: d,
              label: d,
            }))}
            bodyRow={Object.keys(druidData[0]).map((d) => ({ row }) => {
              const cell = Array.isArray(row[d])
                ? `[${row[d].join(', ')}]`
                : row[d];
              return (
                <Tooltip title={cell || ''} placement="top">
                  <Typography noWrap>{cell}</Typography>
                </Tooltip>
              );
            })}
            rows={druidData}
            cellClasses={{ root: classes.cellRoot }}
            // emptyMessage={emptyMessage}
          />
        ) : null}
      </PaperSample>
    </SimpleContainer>
  );
}

const mapStateToProps = (state, props) => {
  const {
    druidData,
    loadingDruidData,
    project,
    selectedDatasourceRow,
  } = state.data;
  return {
    ...state,
    druidData: druidData || [],
    loadingDruidData: loadingDruidData === 'saving',
    id: project.id,
    datasource_id: selectedDatasourceRow && selectedDatasourceRow.datasource_id,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionLoadDruidData: (id, datasource_id, numOfRows, query) =>
      dispatch(
        actionLoadDruidData({
          id,
          datasource_id,
          data: { numOfRows, query: JSON.parse(query) },
        })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DruidTable);
