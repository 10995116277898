import _ from './../../constants';
import * as api from './../../services/api';

class Auth {
  isAuth() {
    return _.STORAGE.getItem(_.SESSION_TOKEN);
  }

  login(data, callback, errorCallback) {
    api.userLogin({
      data,
      success: (data) => {
        if (data) {
          _.STORAGE.setItem(_.SESSION_TOKEN, data.token);
          _.STORAGE.setItem(_.USER_EMAIL, data.email);
          _.STORAGE.setItem(_.USER_ROLES, data.role);
        }

        if (callback) callback();
      },
      error: (e, message) =>
        errorCallback(message ? message : 'Invalid email or password!'),
    });
  }

  logout(callback, errorCallback) {
    api.userLogout({});

    _.STORAGE.removeItem(_.SESSION_TOKEN);
    _.STORAGE.removeItem(_.USER_EMAIL);
    _.STORAGE.removeItem(_.USER_ROLES);

    if (callback) callback();
  }

  get email() {
    return _.STORAGE.getItem(_.USER_EMAIL);
  }

  get roles() {
    return _.STORAGE.getItem(_.USER_ROLES);
  }
}

export default new Auth();
