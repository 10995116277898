import { makeRequest } from './http';

/**
 * API SERVICE
 */

// Token retrieval API
// Authenticate user with email and password
export const userLogin = ({ data, success, error }) => {
  makeRequest({
    path: '/auth/login',
    method: 'POST',
    params: JSON.stringify(data),
    success,
    error,
    errorMessage: 'Invalid email or password!',
  });
};

export const userLogout = ({ success, error }) => {
  makeRequest({
    path: '/auth/logout',
    method: 'GET',
    success,
    error,
  });
};

// PROJECTS

// Get all projects
export const getProjects = () => {
  return makeRequest({
    path: '/project',
    method: 'GET',
    errorMessage: 'Not found projects!',
  });
};

// Get project by id
export const getProject = ({ id }) => {
  return makeRequest({
    path: `/project/${id}`,
    method: 'GET',
    errorMessage: 'Not found project!',
  });
};

// Update project by id
export const updateProject = ({ id, data = {} }) => {
  return makeRequest({
    path: `/project/${id}`,
    method: 'PUT',
    params: JSON.stringify(data),
    successMessage: 'Saved!',
    errorMessage: 'Not update project!',
  });
};

// DATASOURCES

// Get all datasources
export const getDatasources = ({ id }) => {
  return makeRequest({
    path: `/project/${id}/datasource`,
    method: 'GET',
    errorMessage: 'Not found datasources!',
  });
};

// Add new datasource
export const addDatasource = ({ id, data = {} }) => {
  return makeRequest({
    path: `/project/${id}/datasource`,
    method: 'POST',
    params: JSON.stringify(data),
    errorMessage: 'Err adding datasource!',
  });
};

// Delete datasource
export const deleteDatasource = ({ id, datasource_id }) => {
  return makeRequest({
    path: `/project/${id}/datasource/${datasource_id}`,
    method: 'DELETE',
    errorMessage: 'Datasource not deleted!',
  });
};

// Update datasources
export const updateDatasource = ({ id, datasource_id, data }) => {
  return makeRequest({
    path: `/project/${id}/datasource/${datasource_id}`,
    method: 'PUT',
    params: JSON.stringify(data),
    successMessage: 'Saved!',
    errorMessage: 'Datasource not updated!',
  });
};

// DGet datasource data
export const queryData = ({ id, datasource_id, data }) => {
  return makeRequest({
    path: `/project/${id}/datasource/${datasource_id}/data`,
    method: 'POST',
    params: JSON.stringify(data),
    errorMessage: 'Err querying data!',
  });
};

// USERS

// Get all users
export const getUsers = ({ id }) => {
  return makeRequest({
    path: `/project/${id}/user`,
    method: 'GET',
    errorMessage: 'Not found users!',
  });
};

// Get user by id
export const getUser = ({ project_id, user_id, success, error }) => {
  makeRequest({
    path: `/project/${project_id}/user/${user_id}`,
    method: 'GET',
    success,
    error,
    errorMessage: 'Not found user!',
  });
};

// Update user by id
export const updateUser = ({ project_id, user_id, data = {} }) => {
  return makeRequest({
    path: `/project/${project_id}/user/${user_id}`,
    method: 'PUT',
    params: JSON.stringify(data),
    errorMessage: 'Not update user!',
  });
};

// Add new user
export const createUser = ({ id, data = {} }) => {
  return makeRequest({
    path: `/project/${id}/user`,
    method: 'POST',
    params: JSON.stringify(data),
    errorMessage: 'Not create user!',
  });
};

// Delete user by id
export const deleteUser = ({ project_id, user_id }) => {
  return makeRequest({
    path: `/project/${project_id}/user/${user_id}`,
    method: 'DELETE',
    errorMessage: 'Not delete user!',
  });
};

// Add multiple user as JSON
export const createMultipleUser = ({ id, data = {} }) => {
  return makeRequest({
    path: `/project/${id}/users`,
    method: 'POST',
    params: JSON.stringify(data),
    errorMessage: 'Not create multiple user!',
  });
};
