import React, { useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { actionHideModal } from '../actions';
import { TextField, Box, Grid } from '@material-ui/core';
import JsonEditor from '../../Other/JsonEditor';
import ButtonSample from '../../Other/ButtonSample';

const fields = [
  {
    name: 'name',
    label: 'Name',
    required: true,
  },
  {
    name: 'slug',
    label: 'Slug',
    required: true,
  },
  {
    name: 'common_url',
    label: 'Common url',
    required: true,
  },
];

const useStyles = makeStyles((theme) => ({
  footerBodyBlock: {
    display: 'flex',
  },
  blockLeft: {
    position: 'relative',
    // padding: '0px!important',
    '& > div': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  blockRight: {
    // padding: '0px!important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& h6': {
      marginBottom: '.5rem',
    },
  },
}));

const convertDatasource = (datasource) => ({
  config: datasource.config,
  default_user_config: datasource.default_user_config,
});

function AddDatasourceModal({
  config: { handleAddDatasource, datasource },
  actionHideModal,
}) {
  const currentJsonRef = useRef(convertDatasource(datasource));
  const classes = useStyles();
  const [json, setJson] = useState(currentJsonRef.current);
  const [jsonFromInput, setJsonFromInput] = useState('');
  const [fieldsState, setFieldsState] = useState(
    fields.map((el) => ({
      ...el,
      value: currentJsonRef.current.config[el.name],
    }))
  );

  const handleChangeJSON = (newJson) => {
    setFieldsState(
      fieldsState.map((el) =>
        Object.keys(newJson.config).find((key) => key === el.name)
          ? {
              ...el,
              value: newJson.config[el.name],
            }
          : el
      )
    );
    setJson(newJson);
  };

  const requiredCheck = useCallback(() => {
    const err = fieldsState.find(
      (f) =>
        f.required && (!json.config || (json.config && !json.config[f.name]))
    );
    if (err) {
      setFieldsState(
        fieldsState.map((f) =>
          f.name === err.name
            ? {
                ...f,
                error: true,
              }
            : f
        )
      );
      return false;
    } else if (fieldsState.find((f) => f.error))
      setFieldsState(
        fieldsState.map((f) => ({
          ...f,
          error: false,
        }))
      );

    return true;
  }, [fieldsState, json]);

  const bodyRender = () => {
    return (
      <Box position="relative">
        <JsonEditor
          data={jsonFromInput || currentJsonRef.current}
          searchData={json}
          handleJSON={handleChangeJSON}
          style={{ width: '100%' }}
        />
      </Box>
    );
  };

  const bodyLeftRender = useCallback(() => {
    return (
      <Box>
        {fieldsState.map((f) => (
          <Box mb={1.5} key={'add-datasorce-field-block-' + f.name}>
            <TextField
              name={f.name}
              id={'add-datasorce-field-' + f.name}
              size="small"
              label={f.label}
              value={f.value}
              multiline
              rowsMax={4}
              fullWidth={true}
              required={f.required}
              error={f.error}
              onChange={(e) => {
                setJson({
                  ...json,
                  ...{
                    config: {
                      ...json.config,
                      [f.name]: e.target.value,
                    },
                  },
                });
                setJsonFromInput({
                  ...json,
                  ...{
                    config: {
                      ...json.config,
                      [f.name]: e.target.value,
                    },
                  },
                });
                setFieldsState(
                  fieldsState.map((el) =>
                    el.name === f.name
                      ? {
                          ...el,
                          value: e.target.value,
                        }
                      : el
                  )
                );
              }}
            />
          </Box>
        ))}
      </Box>
    );
  }, [fieldsState, json]);

  const footerLeftRender = useCallback(() => {
    return (
      <Box display="flex" justifyContent="space-between">
        <ButtonSample
          name="Cancel"
          color="default"
          method={() => actionHideModal()}
        />
        <ButtonSample
          name="Add"
          method={() =>
            requiredCheck() && json.config && json.config.datasets
              ? handleAddDatasource(json)
              : ''
          }
        />
      </Box>
    );
  }, [fieldsState, json, actionHideModal]);

  return (
    <Grid container spacing={1} className={classes.footerBodyBlock}>
      <Grid item xs={5} className={classes.blockLeft}>
        <Box>
          {bodyLeftRender()}
          {footerLeftRender()}
        </Box>
      </Grid>
      <Grid item xs={7} className={classes.blockRight}>
        <Box pl={1}>{bodyRender()}</Box>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (rootState, props) => {
  const state = rootState.modal;
  return {
    ...state,
    config: state.config.modalConfig,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionHideModal: () => dispatch(actionHideModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDatasourceModal);
