import { all, takeEvery, call, put, select, delay } from 'redux-saga/effects';
import * as actions from '../actions';
import { actionHideModal } from '../../components/Modals/actions';
import * as api from '../../services/api';
import { actionShowNotification } from '../../components/Notification/actions';

function* initProject() {
  yield takeEvery(actions.INIT_PROJECT, function* ({ payload: { id } }) {
    console.log('SAGA: init project');

    // get project
    const project = yield call(api.getProject, { id });

    if (project && !project.error) {
      yield put(actions.actionDidInitProject(project));
    } else {
      console.log('???', project.error);
      yield put(actionShowNotification(project.error, 'error'));
    }
  });
}

function* initProjects() {
  yield takeEvery(actions.INIT_PROJECTS, function* () {
    console.log('SAGA: init projects');

    // get projects
    const projects = yield call(api.getProjects);

    if (projects && !projects.error) {
      yield put(actions.actionDidInitProjects(projects));
    } else {
      console.log('???', projects.error);
      yield put(actionShowNotification(projects.error, 'error'));
    }
  });
}

function* updateProject() {
  yield takeEvery(
    actions.UPDATE_PROJECT,
    function* ({ payload: { id, data } }) {
      console.log('SAGA: update project');

      // update project
      const project = yield call(api.updateProject, { id, data });

      if (project && !project.error) {
        yield put(actions.actionDidUpdateProject(id, data));
        yield put(actionShowNotification(project.success, 'success'));
      } else {
        console.log('???', project.error);
        yield put(actionShowNotification(project.error, 'error'));
      }
    }
  );
}

function* initDatasources() {
  yield takeEvery(actions.INIT_DATASOURCES, function* ({ payload: { id } }) {
    console.log('SAGA: init datasources');

    const loading = yield select((state) => state.data.loadingDatasource);
    if (loading === 'regular')
      yield put(actions.actionStartLoadingDatasource());
    // get datasources
    const datasources = yield call(api.getDatasources, { id });

    if (datasources && !datasources.error) {
      const selectedDatasourceRow = yield select(
        (state) => state.data.selectedDatasourceRow
      );
      yield put(actions.actionDidInitDatasources(datasources));
      if (selectedDatasourceRow === null && datasources.length)
        yield put(
          actions.actionSelectDatasourceRow(
            JSON.parse(JSON.stringify(datasources))[0]
          )
        );
    } else {
      console.log('???', datasources.error);
      yield put(actionShowNotification(datasources.error, 'error'));
    }
    yield put(actions.actionStopLoadingDatasource());
  });
}

function* deleteDatasource() {
  yield takeEvery(
    actions.DELETE_DATASOURCE,
    function* ({ payload: { id, datasource_id } }) {
      console.log('SAGA: delete datasource');

      yield put(actions.actionStartLoadingDatasource());

      // delete datasource
      const datasource = yield call(api.deleteDatasource, {
        id,
        datasource_id,
      });

      if (datasource && !datasource.error) {
        const selectedDatasourceRow = yield select(
          (state) => state.data.selectedDatasourceRow
        );
        if (
          selectedDatasourceRow !== null &&
          selectedDatasourceRow.datasource_id === datasource_id
        )
          yield put(actions.actionSelectDatasourceRow(null));
        yield put(actions.actionInitDatasources({ id }));
      } else {
        console.log('???', datasource.error);
        yield put(actionShowNotification(datasource.error, 'error'));
        yield put(actions.actionStopLoadingDatasource());
      }
    }
  );
}

function* addDatasource() {
  yield takeEvery(
    actions.ADD_DATASOURCE,
    function* ({ payload: { id, data } }) {
      console.log('SAGA: add datasource');

      yield put(actions.actionStartLoadingDatasource());

      // add datasource
      const datasource = yield call(api.addDatasource, {
        id,
        data,
      });

      if (datasource && !datasource.error) {
        yield put(actions.actionInitDatasources({ id }));
        yield put(actionHideModal());
      } else {
        console.log('???', datasource.error);
        yield put(actionHideModal());
        yield put(actionShowNotification(datasource.error, 'error'));
        yield put(actions.actionStopLoadingDatasource());
      }
    }
  );
}

function* updateDatasource() {
  yield takeEvery(
    actions.UPDATE_DATASOURCE,
    function* ({ payload: { id, datasource_id, data } }) {
      console.log('SAGA: update datasource');

      yield put(actions.actionStartLoadingDatasource());

      // update datasource
      const datasource = yield call(api.updateDatasource, {
        id,
        datasource_id,
        data,
      });

      if (datasource && !datasource.error) {
        yield put(actions.actionInitDatasources({ id }));
        yield put(actionShowNotification(datasource.success, 'success'));
      } else {
        console.log('???', datasource.error);
        yield put(actionShowNotification(datasource.error, 'error'));
        yield put(actions.actionStopLoadingDatasource());
      }
    }
  );
}

function* loadDruidData() {
  yield takeEvery(actions.LOAD_DRUID_DATA, function* ({ payload: { id, datasource_id, data } }) {
    console.log('SAGA: load druid data');

    yield put(actions.actionStartLoadingDruidData());
    // yield delay(500);
    
    const __data = yield call(api.queryData, {
      id,
      datasource_id,
      data
    });

    if (__data && !__data.error) {
      // const _data = Array.from({ length: data.numOfRows }, (v, i) => ({
      //   id: i,
      //   first: `example first ${i}`,
      //   second: [0, 12, 34, 234, 34],
      //   third: [`Qwert dgrt ${i}`, `Qwert dgrt ${i+100}`],
      // }));
      yield put(actions.actionDidLoadDruidData(__data));
      yield put(actions.actionStopLoadingDruidData());
    } else {
      console.log('???', __data.error);
      yield put(actionShowNotification(__data.error, 'error'));
      yield put(actions.actionStopLoadingDruidData());
    }
  });
}

export default function* watchGlobalSaga() {
  yield all([
    initProject(),
    initProjects(),
    updateProject(),
    initDatasources(),
    deleteDatasource(),
    addDatasource(),
    updateDatasource(),
    loadDruidData(),
  ]);
}
