import { NotificationActions } from './actions';

const defaultDuration = 2000;
const defaultVertical = 'top';
const defaultHorizontal = 'center';
const defaultNotificationState = {
  open: false,
  duration: defaultDuration,
  text: '',
  severity: 'success',
  vertical: defaultVertical,
  horizontal: defaultHorizontal,
};

export default function notificationReducer(
  state = defaultNotificationState,
  { type, payload }
) {
  switch (type) {
    case NotificationActions.SHOW_APPLICATION_NOTIFICATION: {
      return {
        ...state,
        text: payload.text,
        severity: payload.severity,
        duration: payload.duration || defaultDuration,
        vertical: payload.vertical || defaultVertical,
        horizontal: payload.horizontal || defaultHorizontal,
        open: true,
      };
    }

    case NotificationActions.HIDE_APPLICATION_NOTIFICATION:
      return { ...state, open: false };

    default:
      return state;
  }
}
