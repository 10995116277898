import { useState, useRef } from 'react';
import { useSnackbar } from 'notistack';

function useModal() {
  const [isShowing, setIsShowing] = useState(false);

  function toggleModal() {
    setIsShowing(!isShowing);
  }

  return {
    isShowing,
    toggleModal,
  };
}

// Hook for getting async state
function useAsyncState(initialValue) {
  const [state, setState] = useState(initialValue);
  const stateRef = useRef(state);
  const setter = (x) =>
    new Promise((resolve) => {
      setState(x);
      resolve(x);
    });
  stateRef.current = state;
  return [state, setter, stateRef];
}

function useNotification() {
  const { enqueueSnackbar } = useSnackbar();

  function notification(message, options) {
    enqueueSnackbar(message, { ...options });
  }

  function notificationSuccess(message, options) {
    enqueueSnackbar(message, { variant: 'success', ...options });
  }

  function notificationError(message, options) {
    enqueueSnackbar(message, { variant: 'error', ...options });
  }

  return {
    notification,
    notificationSuccess,
    notificationError,
  };
}

export { useModal, useAsyncState, useNotification };
